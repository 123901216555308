import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { REACT_BACKEND_URL } from './utils/utils';

const Bayaans = () => {
  const { author, type } = useParams();
  const [bayaans, setBayaans] = useState([]);
  const [authorName, setAuthorName] = useState('');
  const [bayanType, setBayanType] = useState('');

  useEffect(() => {
    const fetchBayaans = async () => {
      try {
        let url = `${REACT_BACKEND_URL}/user_bayaans?author=${author}`;
        if (type) {
          url += `&type=${type}`;
          setBayanType(type);
        }
        const response = await fetch(url);
        const data = await response.json();
        setBayaans(data);

        switch (parseInt(author, 10)) {
          case 1:
            setAuthorName("قطب الاقطاب عالم ربانی محدث کبیر حضرت اقدس مولانا سید حامد میاں صاحب نور اللہ مرقدہ");
            break;
          case 2:
            setAuthorName("حضرت مولانا سید محمود میاں");
            break;
          case 3:
            setAuthorName("حضرت مولانا سید مسعود میاں");
            break;
          default:
            setAuthorName("Unknown Author");
        }
      } catch (error) {
        console.error('Error fetching bayaans:', error);
      }
    };

    fetchBayaans();
  }, [author, type]);

  return (
    <div>
      <div className="banner hero-book">
        <div className="container">
          <h1>{authorName}</h1>
        </div>
      </div>

      <section className="inner-section">
        <div className="container">
          <div className="row" style={{ display: 'block' }}>
            <div className="texture-box">
              <div className="col-md-12">
                <div className="bayan1-title">
                  <div className="bar">
                    {bayanType === 'Dars-e-Hadees' ? 'درس حدیث' : bayanType === 'Khutbaat-e-Jummah' ? 'خطباتِ جمعہ' : 'درس حدیث'}
                  </div>
                </div>
              </div>
              <div className="col-md-12">
                <table id="myTable" className="listing" data-order='[[ 0, "desc" ]]'>
                  <thead>
                    <tr>
                      <th></th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    {bayaans.map(bayaan => (
                      <tr key={bayaan.bayaan_id}>
                        <td style={{ display: 'none' }}>{bayaan.bayaan_id}</td>
                        <td style={{ width: '100%' }}>
                          <a
                            className="lister"
                            href="#"
                            data-toggle="modal"
                            data-target={`#exampleModalLong${bayaan.bayaan_id}`}
                          >
                            {bayaan.bayaan_description}
                          </a>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </section>

      {bayaans.map(bayaan => (
        <div
          key={bayaan.bayaan_id}
          className="modal fade"
          id={`exampleModalLong${bayaan.bayaan_id}`}
          tabIndex="-1"
          role="dialog"
          aria-labelledby="exampleModalLongTitle"
          aria-hidden="true"
        >
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body">
                <div className="activeAudio" id="hh">
                  <div className="pro-overlay">
                    <div className="des">
                      <audio
                        src={bayaan.bayaan_link}
                        id={`audio${bayaan.bayaan_id}`}
                        className="overlay-audio"
                        controls
                      >
                        Your Browser does not support the audio format
                      </audio>
                    </div>
                  </div>
                  <div className="overlay-text">
                    <h3>{bayaan.bayaan_name}</h3>
                    <p>{bayaan.bayaan_description}</p>
                  </div>
                  <div className="overlay-pdf">
                    <ul className="social">
                      <li>
                        <a
                          href={`whatsapp://send?text=https://example.com/bayanat/viewpage.php?id=${bayaan.bayaan_id}`}
                          className="ws"
                        >
                          <i className="fab fa-whatsapp"></i>
                        </a>
                      </li>
                      <li>
                        <a
                          href={`https://www.facebook.com/sharer.php?u=https://example.com/bayanat/viewpage.php?id=${bayaan.bayaan_id}`}
                          className="fb"
                        >
                          <i className="fab fa-facebook-f"></i>
                        </a>
                      </li>
                    </ul>
                    <h3>Download PDF</h3>
                    <a href={bayaan.bayaan_pdf} target="_blank" rel="noopener noreferrer">
                      <img src="/images/anwaremadina/book_pdf.png" alt="PDF" />
                    </a>
                  </div>
                </div>
              </div>
              <div className="modal-footer">
                <a
                  className="btn btn-primary"
                  href={`https://example.com/bayanat/viewpage.php?id=${bayaan.bayaan_id}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Open Bayaan Page
                </a>
                <button type="button" className="btn btn-secondary" data-dismiss="modal">
                  Close
                </button>
              </div>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default Bayaans;
