import React from 'react';
import searchIcon from '../images/searchIcon.png';
import './SearchBar.css';

export default function SearchBar() {
  return (
    <div className="search-bar-container">
      <img src={searchIcon} className="search-icon" alt="Search icon" />
      <input type="text" className="form-control" id="q" name="q" placeholder="تلاش کریں" />
    </div>
  );
}
