import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { REACT_BACKEND_URL } from './utils/utils';

const PastYearSection = () => {
  const [years, setYears] = useState([]);
  const navigate = useNavigate();  // useNavigate hook for navigation

  useEffect(() => {
    const fetchYears = async () => {
      try {
        const response = await axios.get(`${REACT_BACKEND_URL}/years`);
        console.log('Fetched years:', response.data);
        setYears(response.data);
      } catch (error) {
        console.error('Error fetching years:', error);
      }
    };
    fetchYears();
  }, []);

  return (
    <section className="product" style={{ boxSizing: 'border-box' }}>
      <h2 className="year-card-heading">گزشتہ شمارہ جات</h2>
      <hr />
      <section id="feature" className="section-p1">
        {years.length > 0 ? (
          years.map(year => (
            <div 
              className="fe-box" 
              key={year.book_year} 
              onClick={() => navigate(`/anwaremadina/2021/year2021.php?year=${year.book_year}`)}
            >
              <img src="../images/anwaremadina/book-img.png" alt="" />
              <h6>{year.book_year}</h6>
            </div>
          ))
        ) : (
          <p>No years available.</p>
        )}
      </section>
    </section>
  );
};

export default PastYearSection;
