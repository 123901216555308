import React from 'react';
import './Maqalat-e-Hamdiyah.css';

const MaqalatHamidiya = () => {
    return (
        <>
            {/* Banner Section */}
            <div className="banner hero-book">
                <div className="container">
                    <h1>مقالاتِ حامدیہ</h1>
                </div>
            </div>

            {/* Inner Section */}
            <section className="inner-section">
                <div className="container">
                    <div className="row" style={{ display: 'block' }}>
                        <div className="texture-box">
                            <div className="col-md-12">
                                <div className="bayan1-title">
                                    <div className="bar">
                                        مقالاتِ حامدیہ
                                        <br />
                                        قطب الاقطاب عالم ربانی محدث کبیر حضرت اقدس مولانا سید حامد میاں صاحب نور اللہ مرقدہ
                                        <br />
                                        <hr style={{ backgroundColor: 'white' }} />
                                        <br />
                                        <span>
                                            شیخ المشائخ محدثِ کبیر حضرت اقدس مولانا سیّد حامد میاں صاحب رحمة اللہ علیہ کے بعض اہم خطوط اور مضامین کو سلسلہ وار شائع کیا گیا جو تاحال طبع نہیں ہوسکے تھے جبکہ ان کی نوع بنوع خصوصیات اس بات کی متقاضی تھیں کہ اِفادۂ عام کی خاطر اِن کو شائع کردیا جائے اسی سلسلہ میں بعض وہ مضامین بھی شائع کیے گئے جو بعض جرا ئد و اَخبارات میں مختلف مواقع پر شائع ہوئے تاکہ ایک ہی لڑی میں تمام مضامین مرتب و یکجا محفوظ ہو جائیں۔
                                        </span>
                                    </div>
                                </div>
                            </div>

                            <div className="col-md-12">
                                <ul className="listing">
                                <li>
                            <a href="https://www.jamiamadniajadeed.org/maqalat/MAQALAT-E-HAMIDIYAH-PDF/MAQALAT-E-HAMIDIYAH-01.pdf" target="_blank">      (1)..........
حضرت مولانا محمود حسن صاحب رحمہ اللہ(شیخ الہند)جنوری 2003</a>
                        </li>
                        <li>
                            <a href="https://www.jamiamadniajadeed.org/maqalat/MAQALAT-E-HAMIDIYAH-PDF/MAQALAT-E-HAMIDIYAH-02.pdf" target="_blank">(2).......... حضرت مولانا سیّد حسین احمد صاحب مدنی رحمہ اللہ۔۔۔۔۔ فروری تا جولائی2003
</a>
                        </li>
                        <li>
                            <a href="https://www.jamiamadniajadeed.org/maqalat/MAQALAT-E-HAMIDIYAH-PDF/MAQALAT-E-HAMIDIYAH-03.pdf" target="_blank">(3).......... حضرت مولانا سیّد محمد میاں صاحب رحمہ اللہ۔۔۔۔۔ اگست /ستمبر2003
</a>
                        </li>
                        <li>
                            <a href="https://www.jamiamadniajadeed.org/maqalat/MAQALAT-E-HAMIDIYAH-PDF/MAQALAT-E-HAMIDIYAH-04.pdf" target="_blank">(4).......... حضرت مولانا حاجی سیّد محمد عابد صاحب رحمہ اللہ (بانی اَول دارُالعلوم دیوبند)۔۔۔۔۔ اکتوبر /نومبر2003
</a>
                        </li>
                        <li>
                            <a href="https://www.jamiamadniajadeed.org/maqalat/MAQALAT-E-HAMIDIYAH-PDF/MAQALAT-E-HAMIDIYAH-05.pdf" target="_blank">(5).......... طلباء کے فرائض۔۔۔۔۔ دسمبر2003
</a>
                        </li>
                        <li>
                            <a href="https://www.jamiamadniajadeed.org/maqalat/MAQALAT-E-HAMIDIYAH-PDF/MAQALAT-E-HAMIDIYAH-06.pdf" target="_blank">(6).......... حضرت مولانا حاجی سیّد محمد عابد صاحب رحمہ اللہ (بانی اوّل دارُالعلوم دیوبند)۔۔۔۔۔ جنوری تا نومبر2004
</a>
                        </li>
                        <li>
                            <a href="https://www.jamiamadniajadeed.org/maqalat/MAQALAT-E-HAMIDIYAH-PDF/MAQALAT-E-HAMIDIYAH-07.pdf" target="_blank">(7).......... خود شناسی۔۔۔۔۔ دسمبر2004
</a>
                        </li>
                        <li>
                            <a href="https://www.jamiamadniajadeed.org/maqalat/MAQALAT-E-HAMIDIYAH-PDF/MAQALAT-E-HAMIDIYAH-08.pdf" target="_blank">(8).......... پردہ کا حکم قرآن پاک میں۔۔۔۔ جنوری2005
</a>
                        </li>
                        <li>
                            <a href="https://www.jamiamadniajadeed.org/maqalat/MAQALAT-E-HAMIDIYAH-PDF/MAQALAT-E-HAMIDIYAH-09.pdf" target="_blank">(9).......... اِسلام اپنے اعلیٰ اوصاف کی وجہ سے دُوسرے سب دینوں پر غالب ہے۔۔۔۔۔ فروری/مارچ 2005
</a>
                        </li>
                        <li>
                            <a href="https://www.jamiamadniajadeed.org/maqalat/MAQALAT-E-HAMIDIYAH-PDF/MAQALAT-E-HAMIDIYAH-10.pdf" target="_blank">(10).......... قرآن کا پیغام ...... ''امن عالم''۔۔۔۔۔ اپریل2005
</a>
                        </li>
                        <li>
                            <a href="https://www.jamiamadniajadeed.org/maqalat/MAQALAT-E-HAMIDIYAH-PDF/MAQALAT-E-HAMIDIYAH-11.pdf" target="_blank">(11).......... قرآنِ پاک۔۔۔۔۔ مئی2005
</a>
                        </li>
                        <li>
                            <a href="https://www.jamiamadniajadeed.org/maqalat/MAQALAT-E-HAMIDIYAH-PDF/MAQALAT-E-HAMIDIYAH-12.pdf" target="_blank">(12).......... قرآنِ پاک کا کلامِ الٰہی ہونا ۔ یہ اللہ کی صفت ہے اور مخلوق نہیں ہے۔۔۔۔۔ جون 2005
</a>
                        </li>
                        <li>
                            <a href="https://www.jamiamadniajadeed.org/maqalat/MAQALAT-E-HAMIDIYAH-PDF/MAQALAT-E-HAMIDIYAH-13.pdf" target="_blank">(13).......... قرآن پاک سے تعلق اور اُس کی برکات۔۔۔۔۔ جولائی 2005
</a>
                        </li>
                        <li>
                            <a href="https://www.jamiamadniajadeed.org/maqalat/MAQALAT-E-HAMIDIYAH-PDF/MAQALAT-E-HAMIDIYAH-14.pdf" target="_blank">(14).......... قرآن وسنت اور تواتر و تعامل۔۔۔۔۔ اگست 2005
</a>
                        </li>
                        <li>
                            <a href="https://www.jamiamadniajadeed.org/maqalat/MAQALAT-E-HAMIDIYAH-PDF/MAQALAT-E-HAMIDIYAH-15.pdf" target="_blank">(15).......... عظمت ِقرآنِ کریم بزبان رسالتمآب ۖ۔۔۔۔۔ ستمبر 2005
</a>
                        </li>
                        <li>
                            <a href="https://www.jamiamadniajadeed.org/maqalat/MAQALAT-E-HAMIDIYAH-PDF/MAQALAT-E-HAMIDIYAH-16.pdf" target="_blank">(16).......... مسائلِ زکٰوة۔۔۔۔۔ اکتوبر 2005
</a>
                        </li>
                        <li>
                            <a href="https://www.jamiamadniajadeed.org/maqalat/MAQALAT-E-HAMIDIYAH-PDF/MAQALAT-E-HAMIDIYAH-17.pdf" target="_blank">(17).......... علامات ِقیامت۔۔۔۔۔ نومبر 2005
</a>
                        </li>
                        <li>
                            <a href="https://www.jamiamadniajadeed.org/maqalat/MAQALAT-E-HAMIDIYAH-PDF/MAQALAT-E-HAMIDIYAH-18.pdf" target="_blank">(18).......... تحویلِ قبلہ۔۔۔۔۔ سمبر2005
</a>
                        </li>
                        <li>
                            <a href="https://www.jamiamadniajadeed.org/maqalat/MAQALAT-E-HAMIDIYAH-PDF/MAQALAT-E-HAMIDIYAH-19.pdf" target="_blank">(19).......... اِنسانی عادات اور اللہ کا عذاب۔۔۔۔۔ جنوری 2006
</a>
                        </li>
                        <li>
                            <a href="https://www.jamiamadniajadeed.org/maqalat/MAQALAT-E-HAMIDIYAH-PDF/MAQALAT-E-HAMIDIYAH-20.pdf" target="_blank">(20).......... مروان اور یزید ؟۔۔۔۔۔ فروری 2006
</a>
                        </li>
                        <li>
                            <a href="https://www.jamiamadniajadeed.org/maqalat/MAQALAT-E-HAMIDIYAH-PDF/MAQALAT-E-HAMIDIYAH-21.pdf" target="_blank">(21).......... یزید حاکم تھا خلیفۂ راشد نہ تھا۔۔۔۔۔ مارچ 2006
</a>
                        </li>
                        <li>
                            <a href="https://www.jamiamadniajadeed.org/maqalat/MAQALAT-E-HAMIDIYAH-PDF/MAQALAT-E-HAMIDIYAH-22.pdf" target="_blank">(22).......... یزید کے بارے میں اَکابر اہل سنت والجماعت کا مسلک۔۔۔۔۔ اپریل 2006
</a>
                        </li>
                        <li>
                            <a href="https://www.jamiamadniajadeed.org/maqalat/MAQALAT-E-HAMIDIYAH-PDF/MAQALAT-E-HAMIDIYAH-23.pdf" target="_blank">(23).......... یزید کے متعلق سوالات اور اُن کے جوابات۔۔۔۔۔ مئی / جون 2006
</a>
                        </li>
                        <li>
                            <a href="https://www.jamiamadniajadeed.org/maqalat/MAQALAT-E-HAMIDIYAH-PDF/MAQALAT-E-HAMIDIYAH-24.pdf" target="_blank">(24).......... یزید اور شراب۔۔۔۔۔ جولائی / اگست 2006
</a>
                        </li>
                        <li>
                            <a href="https://www.jamiamadniajadeed.org/maqalat/MAQALAT-E-HAMIDIYAH-PDF/MAQALAT-E-HAMIDIYAH-25.pdf" target="_blank">(25).......... واقعۂ شہادت ذی النورین سیدنا عثمانِ غنی رضی اللہ عنہ مسئلہ قصاص اور نعرۂ قصاص۔۔۔۔۔ ستمبر تا دسمبر 2006
</a>
                        </li>
                        <li>
                            <a href="https://www.jamiamadniajadeed.org/maqalat/MAQALAT-E-HAMIDIYAH-PDF/MAQALAT-E-HAMIDIYAH-26.pdf" target="_blank">(26).......... آغاز دَورِ فتن۔۔۔۔۔ جنوری تا مارچ 2007
</a>
                        </li>
                        <li>
                            <a href="https://www.jamiamadniajadeed.org/maqalat/MAQALAT-E-HAMIDIYAH-PDF/MAQALAT-E-HAMIDIYAH-27.pdf" target="_blank">(27).......... محمود احمد عباسی کی تاریخی بددیانتی۔۔۔۔۔ اپریل 2007
</a>
                        </li>
                        <li>
                            <a href="https://www.jamiamadniajadeed.org/maqalat/MAQALAT-E-HAMIDIYAH-PDF/MAQALAT-E-HAMIDIYAH-28.pdf" target="_blank">(28).......... منہاج السنة اِزالة الخفاء اور عباسی صاحب کی خیانتیں۔۔۔۔۔ مئی / جون 2007
</a>
                        </li>
                        <li>
                            <a href="https://www.jamiamadniajadeed.org/maqalat/MAQALAT-E-HAMIDIYAH-PDF/MAQALAT-E-HAMIDIYAH-29.pdf" target="_blank">(29).......... مکتوب ِگرامی بنام مولانا محمد نافع صاحب۔۔۔۔۔ جولائی 2007
</a>
                        </li>
                        <li>
                            <a href="https://www.jamiamadniajadeed.org/maqalat/MAQALAT-E-HAMIDIYAH-PDF/MAQALAT-E-HAMIDIYAH-30.pdf" target="_blank">(30).......... حکیم فیض عالم صدیقی کی بے رَاہ روی ( ٨ قسطیں ) ۔۔۔۔۔ اگست 2007 تا مارچ 2008
</a>
                        </li>
                        <li>
                            <a href="https://www.jamiamadniajadeed.org/maqalat/MAQALAT-E-HAMIDIYAH-PDF/MAQALAT-E-HAMIDIYAH-31.pdf" target="_blank">(31).......... حضرت عائشہ رضی اللہ عنہا کی عمر اور حکیم نیاز احمد صاحب کا مغالطہ۔۔۔۔۔ اپریل 2008 تا جولائی 2009
</a>
                        </li>
                        <li>
                            <a href="https://www.jamiamadniajadeed.org/maqalat/MAQALAT-E-HAMIDIYAH-PDF/MAQALAT-E-HAMIDIYAH-32.pdf" target="_blank">(32).......... روزہ ...... تزکیۂ نفس۔۔۔۔۔ اگست 2009
</a>
                        </li>
                        <li>
                            <a href="https://www.jamiamadniajadeed.org/maqalat/MAQALAT-E-HAMIDIYAH-PDF/MAQALAT-E-HAMIDIYAH-33.pdf" target="_blank">(33).......... بُرا نہ مانیے اور غور کیجیے۔۔۔۔۔ ستمبر 2009
</a>
                        </li>
                        <li>
                            <a href="https://www.jamiamadniajadeed.org/maqalat/MAQALAT-E-HAMIDIYAH-PDF/MAQALAT-E-HAMIDIYAH-34.pdf" target="_blank">(34).......... چند ضروری مسائل حج۔۔۔۔۔ اکتوبر 2009
</a>
                        </li>
                        <li>
                            <a href="https://www.jamiamadniajadeed.org/maqalat/MAQALAT-E-HAMIDIYAH-PDF/MAQALAT-E-HAMIDIYAH-35.pdf" target="_blank">(35).......... صرف اِمام اور منفرد ہی کا سورہ ٔفاتحہ پڑھنا اور اُس کے دلائل۔۔۔۔۔ نومبر / دسمبر 2009
</a>
                        </li>
                        <li>
                            <a href="https://www.jamiamadniajadeed.org/maqalat/MAQALAT-E-HAMIDIYAH-PDF/MAQALAT-E-HAMIDIYAH-36.pdf" target="_blank">(36).......... نوائے وقت کی بے وقت راگنی اَور مدنی فارمولا۔۔۔۔۔ جنوری 2010
</a>
                        </li>
                        <li>
                            <a href="https://www.jamiamadniajadeed.org/maqalat/MAQALAT-E-HAMIDIYAH-PDF/MAQALAT-E-HAMIDIYAH-37.pdf" target="_blank">(37).......... قیام ِ پاکستان اور مسلمانانِ بر ِ صغیر کے لیے علماء دیوبند کا بے داغ کردار۔۔۔۔۔ فروری تا مئی 2010
</a>
                        </li>
                        <li>
                            <a href="https://www.jamiamadniajadeed.org/maqalat/MAQALAT-E-HAMIDIYAH-PDF/MAQALAT-E-HAMIDIYAH-38.pdf" target="_blank">(38).......... مدنی فارمولا۔۔۔۔۔ جون/ جولائی 2010
</a>
                        </li>
                        <li>
                            <a href="https://www.jamiamadniajadeed.org/maqalat/MAQALAT-E-HAMIDIYAH-PDF/MAQALAT-E-HAMIDIYAH-39.pdf" target="_blank">(39).......... میرا عقیدہ حیات النبی ۖ۔۔۔۔۔ اگست 2010
</a>
                        </li>
                        <li>
                            <a href="https://www.jamiamadniajadeed.org/maqalat/MAQALAT-E-HAMIDIYAH-PDF/MAQALAT-E-HAMIDIYAH-40.pdf" target="_blank">(40).......... شہد کے فوائد۔۔۔۔۔ ستمبر 2010
</a>
                        </li>
                        <li>
                            <a href="https://www.jamiamadniajadeed.org/maqalat/MAQALAT-E-HAMIDIYAH-PDF/MAQALAT-E-HAMIDIYAH-41.pdf" target="_blank">(41).......... کِتَابُ الطِّبْ۔۔۔۔۔ اکتوبر 2010
</a>
                        </li>
                        <li>
                            <a href="https://www.jamiamadniajadeed.org/maqalat/MAQALAT-E-HAMIDIYAH-PDF/MAQALAT-E-HAMIDIYAH-42.pdf" target="_blank">(42).......... استفتاء قید و بند کوڑے اور جرمانے وغیرہ کے اسلامی احکام۔۔۔۔۔ نومبر /دسمبر 2010
</a>
                        </li>
                        <li>
                            <a href="https://www.jamiamadniajadeed.org/maqalat/MAQALAT-E-HAMIDIYAH-PDF/MAQALAT-E-HAMIDIYAH-43.pdf" target="_blank">(43).......... رجم کا بیان۔۔۔۔۔ جنوری تا مئی 2011
</a>
                        </li>
                        <li>
                            <a href="https://www.jamiamadniajadeed.org/maqalat/MAQALAT-E-HAMIDIYAH-PDF/MAQALAT-E-HAMIDIYAH-44.pdf" target="_blank">(44).......... نفاذ شریعت کا سیدھا راستہ۔۔۔۔۔ جون تا اگست 2011
</a>
                        </li>
                        <li>
                            <a href="https://www.jamiamadniajadeed.org/maqalat/MAQALAT-E-HAMIDIYAH-PDF/MAQALAT-E-HAMIDIYAH-45.pdf" target="_blank">(45).......... حدود وقصاص : عورت کی شہادت۔۔۔۔۔ ستمبر /اکتوبر 2011
</a>
                        </li>
                        <li>
                            <a href="https://www.jamiamadniajadeed.org/maqalat/MAQALAT-E-HAMIDIYAH-PDF/MAQALAT-E-HAMIDIYAH-46.pdf" target="_blank">(46).......... مرد کی دیت کامل اور عورت کی نصف ہوگی۔۔۔۔۔ نومبر 2011
</a>
                        </li>
                        <li>
                            <a href="https://www.jamiamadniajadeed.org/maqalat/MAQALAT-E-HAMIDIYAH-PDF/MAQALAT-E-HAMIDIYAH-47.pdf" target="_blank">(47).......... اِسلام کا اِقتصادی نظام ........ . سوالات و جوابات۔۔۔۔ دسمبر 2011
</a>
                        </li>
                        <li>
                            <a href="https://www.jamiamadniajadeed.org/maqalat/MAQALAT-E-HAMIDIYAH-PDF/MAQALAT-E-HAMIDIYAH-48.pdf" target="_blank">(48).......... پاکستان میں نفاذِ شریعت کے لیے اقدامات۔۔۔۔۔ جنوری 2012
</a>
                        </li>
                        <li>
                            <a href="https://www.jamiamadniajadeed.org/maqalat/MAQALAT-E-HAMIDIYAH-PDF/MAQALAT-E-HAMIDIYAH-49.pdf" target="_blank">(49).......... عام اِنتخابات سے متعلق سوالات و جوابات۔۔۔۔۔ فروری 2012
</a>
                        </li>
                        <li>
                            <a href="https://www.jamiamadniajadeed.org/maqalat/MAQALAT-E-HAMIDIYAH-PDF/MAQALAT-E-HAMIDIYAH-50.pdf" target="_blank">(50).......... سوالات و جوابات۔۔۔۔۔ مارچ 2012
</a>
                        </li>
                        <li>
                            <a href="https://www.jamiamadniajadeed.org/maqalat/MAQALAT-E-HAMIDIYAH-PDF/MAQALAT-E-HAMIDIYAH-51.pdf" target="_blank">(51).......... عالمی اَور ملکی حالات پر دُور اَندیش تبصرہ۔۔۔۔ اپریل 2012
</a>
                        </li>
                        <li>
                            <a href="https://www.jamiamadniajadeed.org/maqalat/MAQALAT-E-HAMIDIYAH-PDF/MAQALAT-E-HAMIDIYAH-52.pdf" target="_blank">(52).......... خارجہ پالیسی اور مستقبل۔۔۔۔۔ مئی 2012
</a>
                        </li>
                        <li>
                            <a href="https://www.jamiamadniajadeed.org/maqalat/MAQALAT-E-HAMIDIYAH-PDF/MAQALAT-E-HAMIDIYAH-53.pdf" target="_blank">(53).......... حضرت مولانا مفتی محمود صاحب رحمہ اللہ۔۔۔۔۔ جون تا اگست 2012
</a>
                        </li>
                        <li>
                            <a href="https://www.jamiamadniajadeed.org/maqalat/MAQALAT-E-HAMIDIYAH-PDF/MAQALAT-E-HAMIDIYAH-54.pdf" target="_blank">(54).......... اَلمُحدث الکبیر (حضرت مولانا محمد یوسف صاحب نوری رحمہ اللہ)۔۔۔۔۔ ستمبر 2012
</a>
                        </li>
                        <li>
                            <a href="https://www.jamiamadniajadeed.org/maqalat/MAQALAT-E-HAMIDIYAH-PDF/MAQALAT-E-HAMIDIYAH-55.pdf" target="_blank">(55).......... شیخ التفسیر حضرت مولانا احمد علی صاحب لاہوری حمہ اللہ۔۔۔۔۔۔ اَکتوبر 2012
</a>
                        </li>
                        <li>
                            <a href="https://www.jamiamadniajadeed.org/maqalat/MAQALAT-E-HAMIDIYAH-PDF/MAQALAT-E-HAMIDIYAH-56.pdf" target="_blank">(56).......... ارضِ دیوبند۔۔۔۔۔ نومبر 2012
</a>
                        </li>
                        <li>
                            <a href="https://www.jamiamadniajadeed.org/maqalat/MAQALAT-E-HAMIDIYAH-PDF/MAQALAT-E-HAMIDIYAH-57.pdf" target="_blank">(57).......... دیوبند میں مسلمانوں کی آباد کاری اور فروغ۔۔۔۔۔ دسمبر 2012
</a>
                        </li>
                        <li>
                            <a href="https://www.jamiamadniajadeed.org/maqalat/MAQALAT-E-HAMIDIYAH-PDF/MAQALAT-E-HAMIDIYAH-58.pdf" target="_blank">(58).......... خونی اِنقلاب ١٨٥٧ء اور اہلِ دیو بند۔۔۔۔۔ جنوری 2013
</a>
                        </li>
                        <li>
                            <a href="https://www.jamiamadniajadeed.org/maqalat/MAQALAT-E-HAMIDIYAH-PDF/MAQALAT-E-HAMIDIYAH-59.pdf" target="_blank">(59).......... محنت اورکسب ِحلال کی اہمیت۔۔۔۔۔ فروری 2013
</a>
                        </li>
                        <li>
                            <a href="https://www.jamiamadniajadeed.org/maqalat/MAQALAT-E-HAMIDIYAH-PDF/MAQALAT-E-HAMIDIYAH-60.pdf" target="_blank">(60).......... عصر حاضر میں طریق ِ اِجتہاد۔۔۔۔۔ مارچ 2013
</a>
                        </li>
                        <li>
                            <a href="https://www.jamiamadniajadeed.org/maqalat/MAQALAT-E-HAMIDIYAH-PDF/MAQALAT-E-HAMIDIYAH-61.pdf" target="_blank">(61).......... جہاد کی ابتداء ،مقاصد ِجہادا ور اُس کی غایت ''جنگ '' اور ''جہاد ''میں فرق۔۔۔۔۔ اپریل 2013
</a>
                        </li>
                        <li>
                            <a href="https://www.jamiamadniajadeed.org/maqalat/MAQALAT-E-HAMIDIYAH-PDF/MAQALAT-E-HAMIDIYAH-62.pdf" target="_blank">(62).......... سیکولراِزم اور قادیانیت۔۔۔۔۔ مئی 2013
</a>
                        </li>
                        <li>
                            <a href="https://www.jamiamadniajadeed.org/maqalat/MAQALAT-E-HAMIDIYAH-PDF/MAQALAT-E-HAMIDIYAH-63.pdf" target="_blank">(63).......... اَلْمُضَارَبَہْ۔۔۔۔۔ جون 2013
</a>
                        </li>
                        <li>
                            <a href="https://www.jamiamadniajadeed.org/maqalat/MAQALAT-E-HAMIDIYAH-PDF/MAQALAT-E-HAMIDIYAH-64.pdf" target="_blank">(64).......... چند عملیات اور وظائف جو ہر آدمی کو کرتے رہنا چاہیے۔۔۔۔۔ جولائی 2013
</a>
                        </li>
                        <li>
                            <a href="https://www.jamiamadniajadeed.org/maqalat/MAQALAT-E-HAMIDIYAH-PDF/MAQALAT-E-HAMIDIYAH-65.pdf" target="_blank">(65).......... مجاہدین ِاسلام کے لیے خاص دُعائیں۔۔۔۔۔ اگست 2013
</a>
                        </li>
                        <li>
                            <a href="https://www.jamiamadniajadeed.org/maqalat/MAQALAT-E-HAMIDIYAH-PDF/MAQALAT-E-HAMIDIYAH-66.pdf" target="_blank">(66).......... حضرت سیّد احمد شہید نور اللہ مرقدہ۔۔۔۔۔ ستمبر 2013
</a>
                        </li>
                        <li>
                            <a href="https://www.jamiamadniajadeed.org/maqalat/MAQALAT-E-HAMIDIYAH-PDF/MAQALAT-E-HAMIDIYAH-67.pdf" target="_blank">(67).......... کیا انسان چاند پر پہنچ سکتا ہے ؟۔۔۔۔۔ اکتوبر /نومبر 2013
</a>
                        </li>
                        <li>
                            <a href="https://www.jamiamadniajadeed.org/maqalat/MAQALAT-E-HAMIDIYAH-PDF/MAQALAT-E-HAMIDIYAH-68.pdf" target="_blank">(68).......... پاکستان میں آئین ِاسلامی کا نفاذ اُس کا طریقہ ، اُس کے فوائد۔۔۔۔۔ دسمبر 2013
</a>
                        </li>
                        <li>
                            <a href="https://www.jamiamadniajadeed.org/maqalat/MAQALAT-E-HAMIDIYAH-PDF/MAQALAT-E-HAMIDIYAH-69.pdf" target="_blank">(69).......... مسلمانوں میں شجاعت کی وجہ ، حیات کیا ہے ؟۔۔۔۔۔ نومبر 2018
</a>
                        </li>
                        <li>
                            <a href="https://www.jamiamadniajadeed.org/maqalat/MAQALAT-E-HAMIDIYAH-PDF/MAQALAT-E-HAMIDIYAH-70.pdf" target="_blank">(70).......... جہاد کی قِسمیں۔۔۔۔۔ دسمبر 2018
</a>
                        </li>
                        <li>
                            <a href="https://www.jamiamadniajadeed.org/maqalat/MAQALAT-E-HAMIDIYAH-PDF/MAQALAT-E-HAMIDIYAH-71.pdf" target="_blank">(71).......... تازہ خواہی داشتن گرد اغہائے سینہ را۔۔۔۔۔ جنوری 2019
</a>
                        </li>
                        <li>
                            <a href="https://www.jamiamadniajadeed.org/maqalat/MAQALAT-E-HAMIDIYAH-PDF/MAQALAT-E-HAMIDIYAH-72.pdf" target="_blank">(72).......... آپ سب سے افضل ، آپ کی اُمت بھی سب سے افضل۔۔۔۔۔ فروری 2019
</a>
                        </li>
                        <li>
                            <a href="https://www.jamiamadniajadeed.org/maqalat/MAQALAT-E-HAMIDIYAH-PDF/MAQALAT-E-HAMIDIYAH-73.pdf" target="_blank">(73).......... قربانی اور نمازِ عید سے متعلق اشکالات کے جوابات۔۔۔۔۔ جولائی 2020
</a>
                        </li>
                        <li>
                            <a href="https://www.jamiamadniajadeed.org/maqalat/MAQALAT-E-HAMIDIYAH-PDF/MAQALAT-E-HAMIDIYAH-74.pdf" target="_blank">(74).......... میں سوچتی ہوں ! جنت میں ہمارا کیا درجہ ہے؟۔۔۔۔۔ ستمبر 2020
</a>
                        </li>
                        <li>
                            <a href="https://www.jamiamadniajadeed.org/maqalat/MAQALAT-E-HAMIDIYAH-PDF/MAQALAT-E-HAMIDIYAH-75.pdf" target="_blank">(75)..........علوم ِ اسلامیہ اور اُن کی بقاء کے لیے اُمت کے فرائض۔۔۔۔۔ اکتوبر 2020</a>
                        </li>
                        <li>
                            <a href="https://www.jamiamadniajadeed.org/maqalat/MAQALAT-E-HAMIDIYAH-PDF/MAQALAT-E-HAMIDIYAH-76.pdf" target="_blank">(76)..........تصوف کیا ہے ؟ ۔۔۔۔۔ فروری 2021</a>
                        </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default MaqalatHamidiya;
