import React from 'react';
import ProductSection from './ProductSection';
import PastYearSection from './PastYearSection';

const AnwareMadina = () => {
    return (
        <div>
            <div className="banner hero-book">
                <div className="container">
                    <h1>انوارِ مدینہ</h1>
                </div>
            </div>
            <ProductSection year="2024" />
            <ProductSection year="2023" />
            <PastYearSection />
        </div>
    );
}

export default AnwareMadina;
