import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { getAuthorNames, REACT_BACKEND_URL } from './utils/utils';

const BayanDetail = () => {
  const { id, bayaanType } = useParams();
  const [bayans, setBayans] = useState([]);

  useEffect(() => {
    const fetchBayanDetail = async () => {
      try {
        let response = {}
        if(bayaanType) response = await fetch(`${REACT_BACKEND_URL}/user_bayaans?author=${getAuthorNames?.[id]}&bayaan_type=${bayaanType}`);
        else response = await fetch(`${REACT_BACKEND_URL}/user_bayaans?author=${getAuthorNames?.[id]}`);
        const data = await response.json();
        if (data.length > 0) {
          setBayans(data);
        } else {
          console.error('Bayan not found');
        }
      } catch (error) {
        console.error('Error fetching bayan detail:', error);
      }
    };

    fetchBayanDetail();
  }, [id,bayaanType]);

  if (!bayans) return <p>Loading bayan details...</p>;

  return (
    <>
    {bayans?.map((bayan)=>{
      return (
        <div key={bayan?.bayaan_id} style={{ padding: '20px', border: '1px solid #ccc' }}>
        <h2>{bayan.bayaan_name}</h2>
        <p><strong>Description:</strong> {bayan.bayaan_description}</p>
        <p><strong>Author:</strong> {bayan.bayaan_author}</p>
        <p><strong>Date Created:</strong> {new Date(bayan.date_created).toLocaleDateString()}</p>
        <audio src={bayan.bayaan_link} controls/>
        <a href={bayan.bayaan_link} target="_blank" rel="noopener noreferrer">Link</a>
        <br />
        <a href={bayan.bayaan_pdf} target="_blank" rel="noopener noreferrer">PDF</a>
      </div>
      )
    })}
  
    </>
  );
};

export default BayanDetail;
