import React, { useState } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import './App.css';
import Tags from './Components/Tags';
import Navbar from './Components/Navbar';
import Carousel from './Components/Carousel';
import SearchBar from './Components/SearchBar';
import NewBook from './Components/NewBook';
import Footer from './Components/Footer';
import EhmBayan from './Components/EhmBayan';
import KhanqahTaruf from './Components/Khanqah/KhanqahTaruf';
import AnwareMadina from './Components/AnwareMadina';
import BookDetail from './Components/BookDetail'; 
import Bayaans from './Components/Bayaans.js';
import BayanDetail from './Components/BayanDetail.js'; 
import MaqalatHamidiya from './Components/Maqalat-e-Hamdiyah.js';
import MaqalatMian from './Components/Maqalat-e-Mian.js';
import DarulIftaPage from './Components/Darulifta.js';

function App() {
  const [mode, setMode] = useState('dark');

  return (
    <Router>
      <Navbar title="خانقاه إمدادية أشرفية" mode={mode} aboutText="" />
      <Routes>
        <Route path="/" element={
          <>
            <div className='container my-2'>
              <SearchBar />
            </div>
            <Carousel heading="Enter Text To Analyze" />
            <KhanqahTaruf />
            <Tags />
            <NewBook />
            <EhmBayan />
            
          </>
        } />
        <Route path="/AnwareMadina" element={<AnwareMadina />} />
        <Route path="/anwaremadina/:slug" element={<BookDetail />} />
        <Route path="/bayaans" element={<Bayaans />} />
        <Route path="/bayaans/:id/:bayaanType" element={<BayanDetail />} />
        <Route path="/bayaans/:id" element={<BayanDetail />} />
        <Route path="/Maqalat-e-Mian" element={<MaqalatMian />} />
        <Route path="/Maqalat-e-Hamdiyah" element={<MaqalatHamidiya />} />
        <Route path="/Darulifta" element={<DarulIftaPage/>} />

      </Routes>
      <Footer />
    </Router>
  );
}

export default App;
