import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';

const BookDetail = () => {
  const { slug } = useParams();
  const [book, setBook] = useState(null);

  useEffect(() => {
    const fetchBook = async () => {
      try {
        const response = await axios.get(`${REACT_BACKEND_URL}/user_books?slug_url=${slug}`);
        if (response.data.length > 0) {
          setBook(response.data[0]);
        } else {
          console.error('No book found with the given slug.');
        }
      } catch (error) {
        console.error('Error fetching book:', error);
      }
    };

    fetchBook();
  }, [slug]);

  if (!book) {
    return <div>Loading...</div>;
  }

  return (
    <div>
      <h1>{book.book_name}</h1>
      <h2>{book.book_subname}</h2>
      <img src={book.book_icon} alt={book.book_name} />
      <p>{book.book_description}</p>
      <a href={book.book_pdf} target="_blank" rel="noopener noreferrer">Download PDF</a>
    </div>
  );
};

export default BookDetail;
