import React from 'react';
import './Footer.css';

export default function Footer() {
  return (
    <div className="footerSection">
      <div className="footerContent">
        <div className="contactInfo">
          <h3>جامعہ العلوم الاسلامیہ</h3>
          <p>P.O. Box : 3465. Karachi-74800</p>
          <p>+92-21-34913570</p>
          <p>+92-21-34121152</p>
          <p>info@banuri.edu.pk</p>
        </div>
        <div className="sections">
          <h4>شعبہ جات</h4>
          <ul>
            <li>مجلہ بینات</li>
            <li>تخریج کی فہرست معلوم کریں</li>
            <li>مسنون اوراد و وظائف</li>
            <li>نماز کے اوقات</li>
            <li>ریڈیو</li>
            <li>طریقہ تعاون</li>
          </ul>
        </div>
        <div className="websiteSections">
          <h4>ویب سائٹ کا نقشہ</h4>
          <ul>
            <li>صفحۂ اوّل</li>
            <li>تعارف</li>
            <li>دارالافتاء</li>
            <li>خبریں</li>
            <li>کتابیں</li>
            <li>اسلامی نام</li>
          </ul>
        </div>
        <div className="methodOfCooperation">
          <h4>طریقہ تعاون</h4>
          <p>
            جامعہ سے مالی تعاون، خیرات، عملیات اور زکوٰۃ و صدقات میں تعاون کے لیے یہاں پر رابطہ کریں۔
          </p>
        </div>
      </div>
      <div className="footerBottom">
        <p>جامعہ علوم اسلامیہ علامہ محمد یوسف بنوری ٹاؤن</p>
      </div>
    </div>
  );
}
