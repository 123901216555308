import React from 'react';
import './Maqalat-e-Mian.css';

const MaqalatMian = () => {
  return (
    <>
   
      
      <style>{`
        .hero-book h1 {
          font-size: 1.7rem;
        }
      `}</style>
      
      <div className="banner hero-book">
        <div className="container">
          <h1>مقالاتِ محمد میاں رحمہ اللہ</h1>
        </div>
      </div>

      <section className="inner-section">
        <div className="container">
          <div className="row" style={{ display: 'block' }}>
            <div className="texture-box">
              <div className="col-md-12">
                <div className="bayan1-title">
                  <div className="bar">مقالات</div>
                </div>
              </div>
              <div className="col-md-12">
                <ul className="listing">
                  <li>
                    <a href="https://www.jamiamadniajadeed.org/maqalat/MAQALAT-E-MUHAMMAD%20MIAN/SILSILA_1_MARCH2015.pdf" target="_blank" rel="noopener noreferrer">
                      (01)..........اسلام کیوں ؟ آفاقی دین ....... صرف اسلام (مارچ 2015)
                    </a>
                  </li>
                  <li>
                    <a href="https://www.jamiamadniajadeed.org/maqalat/MAQALAT-E-MUHAMMAD%20MIAN/SILSILA_2_APRIL2015.pdf" target="_blank" rel="noopener noreferrer">
                      (02)..........جمہوریت'' اپنے آئینہ میں اور اسلامی نظامِ حکومت کا مختصر خاکہ'' (اپریل 2015)
                    </a>
                  </li>
                  <li>
                    <a href="https://www.jamiamadniajadeed.org/maqalat/MAQALAT-E-MUHAMMAD%20MIAN/SILSILA_3_MAY2015.pdf" target="_blank" rel="noopener noreferrer">
                      (03)..........روح کیا ہے ؟ (مئی 2015)
                    </a>
                  </li>
                  <li>
                    <a href="https://www.jamiamadniajadeed.org/maqalat/MAQALAT-E-MUHAMMAD%20MIAN/SILSILA_4_JUNE2015.pdf" target="_blank" rel="noopener noreferrer">
                      (04)..........رمضان شریف، شب ِقدر، اعتکاف (جون 2015)
                    </a>
                  </li>
                  <li>
                    <a href="https://www.jamiamadniajadeed.org/maqalat/MAQALAT-E-MUHAMMAD%20MIAN/SILSILA_5_JULY2015.pdf" target="_blank" rel="noopener noreferrer">
                      (05)..........تکبیر اور تعظیم شعائراللہ کا مقدس دن ''عید'' (جولائی 2015)
                    </a>
                  </li>
                  <li>
                    <a href="https://www.jamiamadniajadeed.org/maqalat/MAQALAT-E-MUHAMMAD%20MIAN/SILSILA_6_AUGUST2015.pdf" target="_blank" rel="noopener noreferrer">
                      (06)..........فتنہ ٔ وضعِ احادیث خوارج اور فتنہ ٔ وضعِ احادیث ۔ حضرت علی کے ہاتھوں ان کی بربادی ( اگست 2015)
                    </a>
                  </li>
                  <li>
                    <a href="https://www.jamiamadniajadeed.org/maqalat/MAQALAT-E-MUHAMMAD%20MIAN/SILSILA_7_SEPTEMBER2015.pdf" target="_blank" rel="noopener noreferrer">
                      (07)..........عید الاضحی ....... اعمال،احکام ،فضائل ( ستمبر 2015)
                    </a>
                  </li>
                  <li>
                    <a href="https://www.jamiamadniajadeed.org/maqalat/MAQALAT-E-MUHAMMAD%20MIAN/SILSILA_8_OCTOBER2015.pdf" target="_blank" rel="noopener noreferrer">
                      (08)..........حیاتِ مسلم کی ایک جھلک ( اکتوبر 2015)
                    </a>
                  </li>
                  <li>
                    <a href="https://www.jamiamadniajadeed.org/maqalat/MAQALAT-E-MUHAMMAD%20MIAN/SILSILA_9_NOVEMBER-DECEMBER2015.pdf" target="_blank" rel="noopener noreferrer">
                      (09)..........اعلیٰ اخلاق کا معلم ( نومبر و دسمبر2015)
                    </a>
                  </li>
                  <li>
                    <a href="https://www.jamiamadniajadeed.org/maqalat/MAQALAT-E-MUHAMMAD%20MIAN/SILSILA_10_JULY2016.pdf" target="_blank" rel="noopener noreferrer">
                      (10)..........دورِ حاضر کے سیاسی اور اقتصادی مسائل اور اسلامی تعلیمات و اشارات (جنوری تا جولائی 2016)
                    </a>
                  </li>
                  <li>
                    <a href="https://www.jamiamadniajadeed.org/maqalat/MAQALAT-E-MUHAMMAD%20MIAN/SILSILA_11_AUGUST2016.pdf" target="_blank" rel="noopener noreferrer">
                      (11)..........سرمایہ ختم کیا جائے یا بخل ؟ ( اگست 2016)
                    </a>
                  </li>
                  <li>
                    <a href="https://www.jamiamadniajadeed.org/maqalat/MAQALAT-E-MUHAMMAD%20MIAN/SILSILA_12_SEPTEMBER2016.pdf" target="_blank" rel="noopener noreferrer">
                      (12)..........معراج سیّد المرسلین رحمة للعالمین ۖ اور رؤیت باری تعالیٰ (ستمبر 2016)
                    </a>
                  </li>
                  <li>
                    <a href="https://www.jamiamadniajadeed.org/maqalat/MAQALAT-E-MUHAMMAD%20MIAN/SILSILA_13_OCTOBER-DECEMBER2016.pdf" target="_blank" rel="noopener noreferrer">
                      (13)..........حیاتِ سیّدنا آدم علیہ السلام (اکتوبر نومبر دسمبر 2016)
                    </a>
                  </li>
                  <li>
                    <a href="https://www.jamiamadniajadeed.org/maqalat/MAQALAT-E-MUHAMMAD%20MIAN/SILSILA_14_JANUARY-SEPTEMBER2017.pdf" target="_blank" rel="noopener noreferrer">
                      (14)..........حیاتِ مسلم (جنوری تا ستمبر 2017)
                    </a>
                  </li>
                  <li>
                    <a href="https://www.jamiamadniajadeed.org/maqalat/MAQALAT-E-MUHAMMAD%20MIAN/SILSILA_15_OCTOBER-DECEMBER2017.pdf" target="_blank" rel="noopener noreferrer">
                      (15)..........صالح جمہوریت اور تعمیر جمہوریت (اکتوبر تا دسمبر 2017)
                    </a>
                  </li>
                  <li>
                    <a href="https://www.jamiamadniajadeed.org/maqalat/MAQALAT-E-MUHAMMAD%20MIAN/SILSILA_16_JANUARY-DECEMBER2018.pdf" target="_blank" rel="noopener noreferrer">
                      (16)..........دین ِ کامل(جنوری تا دسمبر 2018)
                    </a>
                  </li>
                  <li>
                    <a href="https://www.jamiamadniajadeed.org/maqalat/MAQALAT-E-MUHAMMAD%20MIAN/SILSILA_17_JANUARY-JUNE2019.pdf" target="_blank" rel="noopener noreferrer">
                      (17)..........تذکرہ سیّدنا حضرت ابوبکر صدیق (جنوری تا جون 2019)
                    </a>
                  </li>
                  <li>
                    <a href="https://www.jamiamadniajadeed.org/maqalat/MAQALAT-E-MUHAMMAD%20MIAN/SILSILA_18_JULY2019.pdf" target="_blank" rel="noopener noreferrer">
                      (18)..........نصاب کے سلسلہ میں حضرت مولانا محمد میاں صاحب کا ایک اہم مکتوب (جولائی 2019)
                    </a>
                  </li>
                  <li>
                    <a href="#">(19)..........سر زمین ِ ہندوستان کے فضائل (نومبر 2019)</a>
                  </li>
                  <li>
                    <a href="#">(20)..........دربارِ مدینہ اور حب ِ وطن (دسمبر 2019)</a>
                  </li>
                  <li>
                    <a href="#">(21)..........حضرت مولانا سیّد حسین احمد مدنی کا مقام اور مرتبہ (جنوری 2020)</a>
                  </li>
                  <li>
                    <a href="#">(22)..........پیاسی دنیا کو رَہْتِ شاہی کی ضرورت ہے (فروری 2020)</a>
                  </li>
                  <li>
                    <a href="#">(23)..........اتحاد ِ امت اور نظامِ خلافت (مارچ 2020)</a>
                  </li>
                  <li>
                    <a href="#">(24)..........معاشیات ِ اسلام ( اپریل 2020)</a>
                  </li>
                  <li>
                    <a href="#">(25)..........حیات ِ رسول ۖ ایک نمونہ عمل (مئی 2020)</a>
                  </li>
                  <li>
                    <a href="#">(26)..........سرمایہ اور اشتراکیت کا مسئلہ (جون 2020)</a>
                  </li>
                  <li>
                    <a href="#">(27)..........فضائل ِ مجاہدین ( جولائی 2020)</a>
                  </li>
                  <li>
                    <a href="#">(28)..........خاتون ِ جنت ( اگست 2020)</a>
                  </li>
                  <li>
                    <a href="#">(29)..........میرا پیغام (ستمبر 2020)</a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default MaqalatMian;
