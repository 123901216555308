import React from 'react';
import faghanAkhtar from '../images/faghanAkhtar.png';
import alabrar from '../images/alabrar.png';
import './EhmBayan.css';

export default function EhmBayan() {
  return (
    <div className="tags-container">
      <div className="tagBox1" style={{ backgroundColor: 'rgb(236, 217, 217)' }}>
        <div className="headerEhamBayan">
          <h1>اہم بیان</h1>
        </div>
        <div className="contentEhamBayan">
          <img src={faghanAkhtar} className="faghanAkhtar" alt="Faghan Akhtar" />
          <div className="text-content">
            <h6>عرش عظیم کا رب</h6>
            <p>شیخ العرب والعجم عارف باللہ مجددزمانہ حضرت اقدس مولاناشاہ حکیم محمد اخترصاحب رحمۃ اللہ علیہ</p>
            <p>کواللہ تعالی انشاه مندا نے اپنی معرفت اور قرب کی ہو بے مثل دولت <br />بخشی تھی وہ محتاج بیاں نہیں ۔</p>
          </div>
        </div>
        <button type="button" className="btn btn-dark">مزید دیکھیں</button>
      </div>
      <div className="tagBox2" style={{ backgroundColor: 'rgb(236, 217, 217)' }}>
        <div className="headerEhamBayan">
          <h1>خانقاہ کے شب و روز</h1>
        </div>
        <div className="contentEhamBayan">
          <div className="text-content">
            <h6>رجب المرجب1442ھ مطابق مارچ­2021</h6>
            <h6>حضرت والا دامت برکاتہم کی علالت کے بعد</h6>
            <h6>اجازتِ حدیث کا سلسلہ دوبارہ شروع</h6>
            <p>الحمد للہ تعالیٰ! حلیم الامت حضرت اقدس مولانا شاہ حکیم محمد مظہر صاحب دامت برکاتہم طویل علالت کے بعد اللہ رب العزت کے فضل وکرم سے صحت یابی کی نعمت عطا ہونے کے بعد پہلے سے بھی فائق تر ہو کر علمی، اصلاحی، تربیتی خدمات میں ہمہ تن مصروف ہیں، (الحمد للہ الذی بنعمتہ تتم الصالحات)۔</p>
          </div>
        </div>
        <button type="button" className="btn btn-dark">مزید دیکھیں</button>
      </div>
      <div className="tagBox3" style={{ backgroundColor: 'rgb(236, 217, 217)' }}>
        <div className="headerEhamBayan">
          <h1>نیا شمارہ</h1>
        </div>
        <div className="contentEhamBayan">
          <div className="text-content">
            <h6>الابرار</h6>
            <img src={alabrar} className="alabrar" alt="Alabrar" />
            <p>تفصیلات کے لئے مزید دیکھیں۔</p>
          </div>
        </div>
        <button type="button" className="btn btn-dark">مزید دیکھیں</button>
      </div>
    </div>
  );
}
