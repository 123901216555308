import React from 'react';
import './KhanqahTaruf.css';

export default function KhanqahTaruf() {
    return (
        <div className='Container'>
            <div className='Header'>
                <h1>تعارف جامعہ مدنیہ جدید</h1>
            </div>
            <div className='Body'>
                <h3>نَحْمَدُہ وَنُصَلِّیْ عَلٰی رَسُوْلِہِ الْکَرِیم اما بعد</h3>
                <p>
                    باری تعالیٰ کا ارشاد ہے ( فَرِیْق فِی الْجَنَّةِ وَفَرِیْق فِی السَّعِیْرِ ) '' ایک جماعت جنت میں اور ایک جماعت جہنم میں۔<br/>
                    '' صرف انسانوں کی نہیں بلکہ اور آگے بڑھ کر انسانیت کی خدمت اللہ تعالیٰ کو سب سے زیادہ محبوب ہے اسی مقدس خدمت کے لیے اللہ تعالیٰ نے انبیاء اور رسول مبعوث فرمائے اور سب کے آخر میں خاتم النبیین حضرت محمد رسول اللہ صلی اللہ علیہ وسلم کو بھیج کر روشنی اور تاریکی کے فرق کو ہمیشہ کے لیے واضح فرما دیا<br/>
                    روشنی والوں کو اَصْحَابُ الْیَمِیْنِ (Rightist )اور تاریکی والوں کو اَصْحَابُ الشِّمَالِ (Leftist) فرمایا<br/>
                    پہلے نبی سے لے کر آخری نبی تک اور اس کے بعد قیامت تک یہ دونوں جماعتیں عالمی فریقین کے طور پر ایک دوسرے کے مد ِ مقابل رہیں گی،<br/>
                    ''رائیٹسٹ'' انسانوں کی خدمت کے ساتھ انسانیت کی خدمت بھی کرتے رہیں گے جبکہ ''لیفٹسٹ'' بغیر کسی آسمانی رہنمائی کے خود ساختہ منصوبہ بندیوں کے ذریعہ انسانوں کی سطحی خدمت پر ہی اکتفا کریں گے<br/>
                    اپنی عارضی اور ناپائیدار کامیابیوں کو پائیدار تصور کرتے ہوئے خود فریبی کا شکار ہو کر ہمیشہ کا خسارہ اُٹھائیں گے۔<br/>
                    '' اَصْحَابُ الْیَمِیْنِ '' کی قیادت انبیاء علیہم السلام اور قیامت تک آنے والے اُن کے نائبین کریں گے جبکہ '' اَصْحَابُ الشِّمَالِ '' کی قیادت شیاطین ِجن و اِنس کریں گے۔<br/>
                    دائیں بازو والوں کے مقدس مشن کو قیامت تک باقی رکھنے کے لیے حضرت محمد رسول اللہ صلی اللہ علیہ وسلم نے مکہ مکرمہ اور مدینہ منورہ میں بالترتیب ''دارِ ارقم اور صفہ '' جبکہ اطراف میں دُور تک مدارس قائم فرمائے<br/>
                    انسانیت کی خدمت کے لیے دن رات مشغول ''جامعہ مدنیہ جدید'' کی سیر کر لیں<br/>
                    اس کے بانی حضرت اقدس مولانا سیّدحامد میاں صاحب رحمة اللہ علیہ ١٩٥٢ء میں دیوبند سے پاکستان تشریف لائے اوراشارۂ غیبی پر لاہور کو اپنا مسکن بنایا<br/>
                    کچھ عرصہ بعد حضرت نے درس و تدریس اور تزکیہ ٔباطن کا تسلسل شروع فرما دیا<br/>
                    مختلف مقامات و مراحل سے گزرتے ہوئے راوی روڈ اور پھر بالا خر ١٩٨٠ء میں ١٩ کلو میٹر رائیونڈ روڈ لاہور بر لب ِسڑک دو سو کنال سے زائد رقبہ پر محیط '' جامعہ مدنیہ جدید'' میں کام کا آغاز فرما دیا<br/>
                    جہاں تقریبًا چھ سو طلباء دن رات علومِ دینیہ اور علومِ عصریہ جدیدہ حاصل کرنے میں مشغول رہتے ہیں ۔
                </p>
            </div>    
        </div>
    );
}
