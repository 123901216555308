import React from 'react';


const DarulIftaPage = () => {
  const url = "https://www.jamiamadniajadeed.org";

  return (
    <>
      <div className="banner hero-book">
        <div className="container">
          <h1>دارالافتاء</h1>
        </div>
      </div>

      <section className="inner-section">
        <div className="container">
          <div className="row" style={{ display: 'block' }}>
            <div className="texture-box">
              <div className="col-md-12">
                <div className="bayan1-title">
                  <div className="bar">
                    فقہی مضامین <br /> حضرت مولانا ڈاکٹر مفتی عبدالواحد صاحب رحمة اللہ علیہ
                  </div>
                </div>
              </div>
              <div className="col-md-12">
                <div className="bayan1-title">
                  <div className="bar">
                    <div className="row">
                      <div className="col-md-6 col-12">
                        <p style={{ fontSize: '1.4rem' }}>
                          نام کتاب: فقہی مضامین(صفحات: 630)
                          <br />
                          <br />
                          یہ کتاب حضرت مصنفؒ کے مختلف اوقات میں لکھے گئے 41 فقہی و کلامی مضامین کا مجموعہ ہے۔ جن میں
                          سے چند مضامین یہ ہیں: شرک کی اقسام، مارٹن لنگز کا فلسفہ وحدت ادیان، مشاجرات صحابہ سے متعلق اہم
                          اصول، محافل قراءت کی شرعی حیثیت، ملٹی لیول مارکیٹنگ، فاریکس کامیکس کے کاروبار کی شرعی حیثیت
                          وغیرہ
                        </p>
                        <div className="button_box">
                          <a href="#" className="btn" id="btn-contact">
                            Contact Us
                          </a>
                        </div>
                      </div>
                      <div className="col-md-6 col-12">
                        <img
                          src={`${url}/darulifta/mazameen/bookImg.png`}
                          height="300px"
                          alt="Book Image"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-12">
                <ul className="listing">
                  {[
                       { link: `${url}/darulifta/mazameen/FIQHI MAZAMEEN NO.01(10-1992).pdf`, text: 'داڑھی سے متعلق ایک استفتاء .....  اکتوبر 1992' },
                       { link: `${url}/darulifta/mazameen/FIQHI MAZAMEEN NO.02(11-1992).pdf`, text: 'متفرق مسائل(سرکاری مراعات کے احکام، ڈھول بجانا، نماز میں غیر اللہ کا خیال آنا ،قسطوں کے کاروبار کے احکام ، انعامی بانڈز کے احکام ) ۔۔۔۔۔ نومبر 1992' },
                       { link: `${url}/darulifta/mazameen/FIQHI MAZAMEEN NO.03(12-1992).pdf`, text: 'متفرق مسائل (اپنی زندگی میں جائیداد وغیرہ اولاد کو ہبہ کرنا ، طلاق سے متعلق ایک سوال ، قبر میں میت سے ایک سوال  کے بارے میں اشکال) ۔۔۔۔۔۔ دسمبر 1992' },
                       { link: `${url}/darulifta/mazameen/FIQHI MAZAMEEN NO.04(01-1993).pdf`, text: 'صفحہ قرطاس  ۔۔۔۔۔ جنوری 1993' },
                       { link: `${url}/darulifta/mazameen/FIQHI MAZAMEEN NO.05(02-1993).pdf`, text: 'گیارھویں شریف اور ایصالِ ثواب کا طریقہ ۔۔۔۔۔ فروری 1993' },
                       { link: `${url}/darulifta/mazameen/FIQHI MAZAMEEN NO.06(03-1993).pdf`, text: 'پیلی ٹیکسی کی خریداری کا شرعی حکم  ۔۔۔۔۔ مارچ 1993' },
                       { link: `${url}/darulifta/mazameen/FIQHI MAZAMEEN NO.07(04-1993).pdf`, text: 'لائوڈ سپیکر کا استعمال   ۔۔۔۔۔۔ اپریل 1993' },
                       { link: `${url}/darulifta/mazameen/FIQHI MAZAMEEN NO.08(05-1993).pdf`, text: 'متفرق مسائل (مالک کی طرف سے دی گئی ضرورت کی اشیاء کو بیچنے کے احکام،ٹیلی ویژن دیکھنا کہاں تک جائز ہے، تصویر والے کھلونوں کا حکم،گورنمنٹ کا اپنے خرچ پر غریبوں کو حج پر بھیجنا، خاندانی منصوبہ بندی کے احکام ) ۔۔۔ مئی 1993' },
                       { link: `${url}/darulifta/mazameen/FIQHI MAZAMEEN NO.09(06-1993).pdf`, text: 'جمعہ کے خطبہ کے مسائل ۔۔۔۔۔ جون 1993' },
                       { link: `${url}/darulifta/mazameen/FIQHI MAZAMEEN NO.10(07-1993).pdf`, text: 'غیر اسلامی نظام حکومت  ۔۔۔۔۔ جولائی 1993' },
                       { link: `${url}/darulifta/mazameen/FIQHI MAZAMEEN NO.11(08 & 09-1993).pdf`, text: 'ہمارے اکابر کا اصل مسلک  ۔۔۔۔۔ اگست/ستمبر 1993' },
                       { link: `${url}/darulifta/mazameen/FIQHI MAZAMEEN NO.12(10-1993).pdf`, text: 'اسم اعظم کیا ہے  ؟   ۔۔۔۔۔ ۔کتوبر 1993' },
                       { link: `${url}/darulifta/mazameen/FIQHI MAZAMEEN NO.13(11-1993).pdf`, text: 'سودی کاروبار کا حکم  ۔۔۔۔۔ نومبر 1993' },
                       { link: `${url}/darulifta/mazameen/FIQHI MAZAMEEN NO.14(12-1993).pdf`, text: 'عیسائی عورت کے قبولِ اسلام کے بعد نکاح کا حکم  ۔۔۔۔۔ دسمبر 1993' },
                       { link: `${url}/darulifta/mazameen/FIQHI MAZAMEEN NO.15(01-1994).pdf`, text: 'نماز جنازہ کے بعد کی دُعا کا حکم ۔۔۔۔۔ جنوری 1994 ' },
                       { link: `${url}/darulifta/mazameen/FIQHI MAZAMEEN NO.16(02-1994).pdf`, text: 'قرض لین دین کا حکم  ۔۔۔۔۔ فروری 1994' },
                       { link: `${url}/darulifta/mazameen/FIQHI MAZAMEEN NO.17(03-1994).pdf`, text: 'تفرق مسائل(اجارہ یعنی ملازمت کے احکام،ٹوکن سکیم کا حکم) ۔۔۔۔۔ مارچ 1994 ' },
                       { link: `${url}/darulifta/mazameen/FIQHI MAZAMEEN NO.18(04-1994).pdf`, text: 'مساجد کمیٹیوں کی شرعی حیثیت   ۔۔۔۔۔ اپریل 1994' },
                       { link: `${url}/darulifta/mazameen/FIQHI MAZAMEEN NO.19(05-1994).pdf`, text: 'تبلیغ کا حکم   ۔۔۔۔۔۔  مئی 1994  ' },
                       { link: `${url}/darulifta/mazameen/FIQHI MAZAMEEN NO.20(06-1994).pdf`, text: 'زندہ جانوروں کی تول کر خرید و فروخت کے احکام    ۔۔۔۔۔ جون  1994' },
                       { link: `${url}/darulifta/mazameen/FIQHI MAZAMEEN NO.21(07-1994).pdf`, text: 'سود کے احکام   ۔۔۔۔۔ جولائی 1994' },
                       { link: `${url}/darulifta/mazameen/FIQHI MAZAMEEN NO.22(08 & 09 & 10 & 11-1994).pdf`, text: 'اصلاحِ مفاہیم پر ایک نظر  ۔۔۔۔۔  اگست / ستمبر / اکتوبر 1994' },
                       { link: `${url}/darulifta/mazameen/FIQHI MAZAMEEN NO.23(10-1994).pdf`, text: 'فاریکس اور کامیکس کمپنیوں کا حکم   ۔۔۔۔۔ اکتوبر 1994' },
                       { link: `${url}/darulifta/mazameen/FIQHI MAZAMEEN NO.24(11-1994).pdf`, text: 'مروجہ انعامی سکیموں کا حکم   ۔۔۔۔۔ نومبر 1994' },
                       { link: `${url}/darulifta/mazameen/FIQHI MAZAMEEN NO.25(12-1994).pdf`, text: 'وراثت کے احکام   ۔۔۔۔۔ دسمبر 1994' },
                       { link: `${url}/darulifta/mazameen/FIQHI MAZAMEEN NO.26(02-1995).pdf`, text: 'مدارس میں زکوة و صدقات کے احکام ۔۔۔۔۔ فروری 1995' },
                       { link: `${url}/darulifta/mazameen/FIQHI MAZAMEEN NO.27(03-1995).pdf`, text: 'غیر اسلامی حکومت کے احکام ۔۔۔۔۔ مارچ 1995' },
                       { link: `${url}/darulifta/mazameen/FIQHI MAZAMEEN NO.28(04-1995).pdf`, text: 'قرض کے احکام ۔۔۔۔۔ اپریل 1995' },
                       { link: `${url}/darulifta/mazameen/FIQHI MAZAMEEN NO.29(05-1995).pdf`, text: 'تفرق مسائل(رمضان میں تراویح اور رکعت کی تعداد) ۔۔۔۔۔ مئی 1995' },
                       { link: `${url}/darulifta/mazameen/FIQHI MAZAMEEN NO.30(06-1995).pdf`, text: 'سود کا شرعی حکم ۔۔۔۔۔ جون 1995' },
                       { link: `${url}/darulifta/mazameen/FIQHI MAZAMEEN NO.31(07 & 09-1995).pdf`, text: 'تفرق مسائل(کھڑے ہوکر پانی پینے کے احکام،وراثت میں خواتین کا حصہ) ۔۔۔۔۔ جولائی/ستمبر 1995' },
                       { link: `${url}/darulifta/mazameen/FIQHI MAZAMEEN NO.32(08-1995).pdf`, text: 'زکوة اور صدقات کے احکام ۔۔۔۔۔ اگست 1995' },
                       { link: `${url}/darulifta/mazameen/FIQHI MAZAMEEN NO.33(10-1995).pdf`, text: 'تفرق مسائل(نماز میں سجدہ سہو کے احکام، چوری شدہ مال کا حکم) ۔۔۔۔۔ اکتوبر 1995' },
                       { link: `${url}/darulifta/mazameen/FIQHI MAZAMEEN NO.34(11-1995).pdf`, text: 'شیئرز کی خرید وفروخت نا جائز ہے   ۔۔۔۔۔  دسمبر 1995'},
                       { link: `${url}/darulifta/mazameen/FIQHI MAZAMEEN NO.35(12-1995).pdf`, text: "حق ایجاد '' اور ''حق طباعت و اشاعت'' کی خرید و فروخت کی شرعی حیثیت ۔۔۔۔۔  جون 1996    جولائی 1996" },
                       { link: `${url}/darulifta/mazameen/FIQHI MAZAMEEN NO.36(01-1996).pdf`, text: 'قربانی سے متعلق ایک استفتاء  ۔۔۔۔۔  اگست 1996 '},
                       { link: `${url}/darulifta/mazameen/FIQHI MAZAMEEN NO.37(02-1996).pdf`, text: 'زکوة سے متعلق ایک استفتاء  ۔۔۔۔۔  ستمبر 1996' },
                       { link: `${url}/darulifta/mazameen/FIQHI MAZAMEEN NO.38(03-1996).pdf`, text: 'علاقائی حقوق سے متعلق احکام    ۔۔۔۔۔ اکتوبر/ نومبر 1996' },
                       { link: `${url}/darulifta/mazameen/FIQHI MAZAMEEN NO.39(04-1996).pdf`, text: 'غیر اسلامی ممالک میں عقود فاسدہ اور عقودِ باطلہ کا حکم  ۔۔۔۔۔ فروری 1997' },
                       { link: `${url}/darulifta/mazameen/FIQHI MAZAMEEN NO.40(05-1996).pdf`, text: 'تحفہ ٔ  اصلاحی     ۔۔۔۔۔ نومبر 1995 تا ستمبر 1996    و  اکتوبر1996 تا اپریل 1997' },
                       { link: `${url}/darulifta/mazameen/FIQHI MAZAMEEN NO.41(06-1996).pdf`, text: 'اہلِ علم و اہلِ فتوی کے غور وفکر کے لیے ایک مسئلہ  ۔۔۔۔۔  مئی 1997 '},
                       { link: `${url}/darulifta/mazameen/FIQHI MAZAMEEN NO.42(07-1996).pdf`, text: 'قرآن پاک اور حروفِ سبعہ پر اُس کا نزول  ۔۔۔۔۔ جون 1997 '},
                       { link: `${url}/darulifta/mazameen/FIQHI MAZAMEEN NO.43(08 & 09-1996).pdf`, text: 'تاریخ ِقرا ء ٰت ِمتواترہ اَور حل اشکالات     ۔۔۔۔۔  جولائی  تا  ستمبر 1997 '},
                       { link: `${url}/darulifta/mazameen/FIQHI MAZAMEEN NO.44(10-1996).pdf`, text: 'مشاجرات ِصحابہ  سے متعلق چند اُصول   ۔۔۔۔۔ نومبر 1997 '},
                       { link: `${url}/darulifta/mazameen/FIQHI MAZAMEEN NO.45(11-1996).pdf`, text: 'عورت سربراہِ مملکت نہیں بن سکتی ہے  ۔۔۔۔۔ دسمبر 1997 '},
                       { link: `${url}/darulifta/mazameen/FIQHI MAZAMEEN NO.46(12-1996).pdf`, text: 'اُصولِ بدعت    ۔۔۔۔۔ مارچ/اپریل 1998 '},
                       { link: `${url}/darulifta/mazameen/FIQHI MAZAMEEN NO.47(01-1997).pdf`, text: 'تفرق مسائل(نماز جنازہ اور غسل کے احکام) ۔۔۔۔۔ جنوری 1997' },
                       { link: `${url}/darulifta/mazameen/FIQHI MAZAMEEN NO.48(02-1997).pdf`, text: 'تفرق مسائل(زکوة اور صدقات کے احکام) ۔۔۔۔۔ فروری 1997' },
                       { link: `${url}/darulifta/mazameen/FIQHI MAZAMEEN NO.49(03-1997).pdf`, text: 'تفرق مسائل(سودی کاروبار کے احکام) ۔۔۔۔۔ مارچ 1997' },
                       { link: `${url}/darulifta/mazameen/FIQHI MAZAMEEN NO.50(04-1997).pdf`, text: 'تفرق مسائل(زکوة اور صدقات کے احکام) ۔۔۔۔۔ اپریل 1997' },
                       { link: `${url}/darulifta/mazameen/FIQHI MAZAMEEN NO.51(05-1997).pdf`, text: 'تفرق مسائل(نماز جنازہ اور غسل کے احکام) ۔۔۔۔۔ مئی 1997' },
                       { link: `${url}/darulifta/mazameen/FIQHI MAZAMEEN NO.52(06-1997).pdf`, text: 'تفرق مسائل(سودی کاروبار کے احکام) ۔۔۔۔۔ جون 1997' },
                       { link: `${url}/darulifta/mazameen/FIQHI MAZAMEEN NO.53(07-1997).pdf`, text: 'تفرق مسائل(زکوة اور صدقات کے احکام) ۔۔۔۔۔ جولائی 1997' },
                       { link: `${url}/darulifta/mazameen/FIQHI MAZAMEEN NO.54(08-1997).pdf`, text: 'تفرق مسائل(نماز جنازہ اور غسل کے احکام) ۔۔۔۔۔ اگست 1997' },
                       { link: `${url}/darulifta/mazameen/FIQHI MAZAMEEN NO.55(09-1997).pdf`, text: 'تفرق مسائل(سودی کاروبار کے احکام) ۔۔۔۔۔ ستمبر 1997' },
                       { link: `${url}/darulifta/mazameen/FIQHI MAZAMEEN NO.56(10-1997).pdf`, text: 'تفرق مسائل(زکوة اور صدقات کے احکام) ۔۔۔۔۔ اکتوبر 1997' },
                       { link: `${url}/darulifta/mazameen/FIQHI MAZAMEEN NO.57(11-1997).pdf`, text: 'تفرق مسائل(نماز جنازہ اور غسل کے احکام) ۔۔۔۔۔ نومبر 1997' },
                       { link: `${url}/darulifta/mazameen/FIQHI MAZAMEEN NO.58(12-1997).pdf`, text: 'تفرق مسائل(سودی کاروبار کے احکام) ۔۔۔۔۔ دسمبر 1997' },
                       { link: `${url}/darulifta/mazameen/FIQHI MAZAMEEN NO.59(01-1998).pdf`, text: 'تفرق مسائل(زکوة اور صدقات کے احکام) ۔۔۔۔۔ جنوری 1998' },
                       { link: `${url}/darulifta/mazameen/FIQHI MAZAMEEN NO.60(05 & 06-2000).pdf`, text: 'تفرق مسائل(نماز جنازہ اور غسل کے احکام) ۔۔۔۔۔ فروری 1998' },     
                       { link: `${url}/darulifta/mazameen/FIQHI MAZAMEEN NO.61(09 & 10-2000).pdf`, text: 'تفرق مسائل(نماز جنازہ اور غسل کے احکام) ۔۔۔۔۔ فروری 1998' },                  
                       { link: `${url}/darulifta/mazameen/FIQHI MAZAMEEN NO.62(03-2001).pdf`, text: 'تفرق مسائل(نماز جنازہ اور غسل کے احکام) ۔۔۔۔۔ فروری 1998' },                  
                       { link: `${url}/darulifta/mazameen/FIQHI MAZAMEEN NO.63(03-2001).pdf`, text: 'تفرق مسائل(نماز جنازہ اور غسل کے احکام) ۔۔۔۔۔ فروری 1998' },                  
                       { link: `${url}/darulifta/mazameen/FIQHI MAZAMEEN NO.64(05 to 12-2001).pdf`, text: 'تفرق مسائل(نماز جنازہ اور غسل کے احکام) ۔۔۔۔۔ فروری 1998' },                  
                       { link: `${url}/darulifta/mazameen/FIQHI MAZAMEEN NO.65(09-2001).pdf`, text: 'تفرق مسائل(نماز جنازہ اور غسل کے احکام) ۔۔۔۔۔ فروری 1998' },                  
                       { link: `${url}/darulifta/mazameen/FIQHI MAZAMEEN NO.66(12-2001).pdf`, text: 'تفرق مسائل(نماز جنازہ اور غسل کے احکام) ۔۔۔۔۔ فروری 1998' },                  
                       { link: `${url}/darulifta/mazameen/FIQHI MAZAMEEN NO.67(02-2002).pdf`, text: 'تفرق مسائل(نماز جنازہ اور غسل کے احکام) ۔۔۔۔۔ فروری 1998' },                  
                       { link: `${url}/darulifta/mazameen/FIQHI MAZAMEEN NO.68(03-2002).pdf`, text: 'تفرق مسائل(نماز جنازہ اور غسل کے احکام) ۔۔۔۔۔ فروری 1998' },                  
                       { link: `${url}/darulifta/mazameen/FIQHI MAZAMEEN NO.69(07-2002).pdf`, text: 'تفرق مسائل(نماز جنازہ اور غسل کے احکام) ۔۔۔۔۔ فروری 1998' },      
                       { link: `${url}/darulifta/mazameen/FIQHI MAZAMEEN NO.70(04-2003).pdf`, text: 'تفرق مسائل(نماز جنازہ اور غسل کے احکام) ۔۔۔۔۔ فروری 1998' },                  
                       { link: `${url}/darulifta/mazameen/FIQHI MAZAMEEN NO.71(05-2003).pdf`, text: 'تفرق مسائل(نماز جنازہ اور غسل کے احکام) ۔۔۔۔۔ فروری 1998' },                  
                       { link: `${url}/darulifta/mazameen/FIQHI MAZAMEEN NO.72(01 & 02 & 03-2004).pdf`, text: 'تفرق مسائل(نماز جنازہ اور غسل کے احکام) ۔۔۔۔۔ فروری 1998' },                  
                       { link: `${url}/darulifta/mazameen/FIQHI MAZAMEEN NO.73(06-2005).pdf`, text: 'تفرق مسائل(نماز جنازہ اور غسل کے احکام) ۔۔۔۔۔ فروری 1998' },                  
                       { link: `${url}/darulifta/mazameen/FIQHI MAZAMEEN NO.74(07-2005).pdf`, text: 'تفرق مسائل(نماز جنازہ اور غسل کے احکام) ۔۔۔۔۔ فروری 1998' },                  
                       { link: `${url}/darulifta/mazameen/FIQHI MAZAMEEN NO.75(10 & 11-2005).pdf`, text: 'تفرق مسائل(نماز جنازہ اور غسل کے احکام) ۔۔۔۔۔ فروری 1998' },                  
                       { link: `${url}/darulifta/mazameen/FIQHI MAZAMEEN NO.76(04-2006).pdf`, text: 'تفرق مسائل(نماز جنازہ اور غسل کے احکام) ۔۔۔۔۔ فروری 1998' },                  
                       { link: `${url}/darulifta/mazameen/FIQHI MAZAMEEN NO.77(11-2006).pdf`, text: 'تفرق مسائل(نماز جنازہ اور غسل کے احکام) ۔۔۔۔۔ فروری 1998' },                  
                       { link: `${url}/darulifta/mazameen/FIQHI MAZAMEEN NO.78(01-2007).pdf`, text: 'تفرق مسائل(نماز جنازہ اور غسل کے احکام) ۔۔۔۔۔ فروری 1998' },                  
                       { link: `${url}/darulifta/mazameen/FIQHI MAZAMEEN NO.79(09-2007).pdf`, text: 'تفرق مسائل(نماز جنازہ اور غسل کے احکام) ۔۔۔۔۔ فروری 1998' },                  
                       { link: `${url}/darulifta/mazameen/FIQHI MAZAMEEN NO.80(11-2007).pdf`, text: 'تفرق مسائل(نماز جنازہ اور غسل کے احکام) ۔۔۔۔۔ فروری 1998' },                  
                       { link: `${url}/darulifta/mazameen/FIQHI MAZAMEEN NO.81(02-2008).pdf`, text: 'تفرق مسائل(نماز جنازہ اور غسل کے احکام) ۔۔۔۔۔ فروری 1998' },                  
                       { link: `${url}/darulifta/mazameen/FIQHI MAZAMEEN NO.82(04-2008).pdf`, text: 'تفرق مسائل(نماز جنازہ اور غسل کے احکام) ۔۔۔۔۔ فروری 1998' },       
                       { link: `${url}/darulifta/mazameen/FIQHI MAZAMEEN NO.83(06 & 07-2008).pdf`, text: 'تفرق مسائل(نماز جنازہ اور غسل کے احکام) ۔۔۔۔۔ فروری 1998' },                  
                       { link: `${url}/darulifta/mazameen/FIQHI MAZAMEEN NO.84(08 & 09 & 10-2008).pdf`, text: 'تفرق مسائل(نماز جنازہ اور غسل کے احکام) ۔۔۔۔۔ فروری 1998' },                  
                       { link: `${url}/darulifta/mazameen/FIQHI MAZAMEEN NO.85(03-2009).pdf`, text: 'تفرق مسائل(نماز جنازہ اور غسل کے احکام) ۔۔۔۔۔ فروری 1998' },                  
                       { link: `${url}/darulifta/mazameen/FIQHI MAZAMEEN NO.86(04-2012 to 12-2012).pdf`, text: 'تفرق مسائل(نماز جنازہ اور غسل کے احکام) ۔۔۔۔۔ فروری 1998' },                  
                       { link: `${url}/darulifta/mazameen/FIQHI MAZAMEEN NO.87(11-2013).pdf`, text: 'تفرق مسائل(نماز جنازہ اور غسل کے احکام) ۔۔۔۔۔ فروری 1998' },        
                       { link: `${url}/darulifta/mazameen/FIQHI MAZAMEEN NO.88(07-2017).pdf`, text: 'تفرق مسائل(نماز جنازہ اور غسل کے احکام) ۔۔۔۔۔ فروری 1998' }
                  ].map(item => (
                    <li key={item.link}>
                      <a href={item.link} target="_blank" rel="noopener noreferrer">{item.text}</a>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default DarulIftaPage;
