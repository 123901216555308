import React from 'react';
import './Tags.css';
import playIcon from '../images/playIcon.png';
import speakerIcon from '../images/speakerIcon.png';
import book from '../images/book.png';
import Allah from '../images/Allah.png';

export default function Tags() {
    return (
        <div className="tags-container">
            <div className="tag-box1" style={{ backgroundColor: 'rgb(236, 217, 217)' }}>
                <div className="playIconContainer">
                    <img src={playIcon} className="playIcon" alt="Play icon" />
                </div>
                <p>
                    <span>خانقاه امدادیہ اشرفیہ<br /> کی لائیو مجالس</span>
                </p>
            </div>
            <div className="tag-box2" style={{ backgroundColor: 'rgb(236, 217, 217)' }}>
                <div className="playIconContainer">
                    <img src={speakerIcon} className="speakerIcon" alt="Speaker icon" />
                </div>
                <p>
                    <span style={{ color: 'brown' }}>مختصر سوتیات</span>
                </p>
            </div>
            <div className="tag-box3" style={{ backgroundColor: 'rgb(236, 217, 217)' }}>
                <div className="playIconContainer">
                    <img src={book} className="book" alt="" />
                </div>
                <p>
                    <span style={{ color: 'brown' }}>کتب</span>
                </p>
            </div>
             <div className="tag-box4" style={{ backgroundColor: 'rgb(236, 217, 217)' }}>
                <div className="Allah">
                    <img src={Allah} className="Allah" alt="" />
                </div>
                <p>
                    <span style={{ color: 'brown' }}>بیانات</span>
                </p>
            </div>
        </div>
    );
}
