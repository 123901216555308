import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom'; // Import Link for client-side navigation
import './Navbar.css';

export default function Navbar(props) {
  return (
    <nav className={`navbar navbar-expand-lg navbar-${props.mode} bg-${props.mode}`}>
      <div className="container-fluid">
        <div className="d-flex">
          <div className="dropdown me-auto">
            <ul className="dropdown-menu">
              <li><a className="dropdown-item" href="#">English</a></li>
              <li><a className="dropdown-item" href="#">Urdu</a></li>
              <li><a className="dropdown-item" href="#">Arabic</a></li>
            </ul>
          </div>
  
          <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
            <span className="navbar-toggler-icon"></span>
          </button>
        </div>
        <div className="collapse navbar-collapse" id="navbarNav">
          <ul className="navbar-nav ms-auto">
            <li className="nav-item">
              <Link className="nav-link" to="/">Home</Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link" to="/AnwareMadina">Anwar-e-Madina</Link>
            </li>
            <li className="nav-item dropdown">
            <Link className="nav-link" to="/">Bayanat</Link>
              <ul className="dropdown-menu">
                <li><Link className="dropdown-item" to="/bayaans/hamid">Molana Syed Hamid Mian Sahab</Link></li>
                <li className="dropdown-submenu">
                  <Link className="dropdown-item" to="/bayaans/mahmood">Molana Syed Mahmood Mian Sahab</Link>
                  <ul className="dropdown-menu">
                    <li><Link className="dropdown-item" to="/bayaans/mahmood/Dars-e-Hadees">Dars-e-Hadees</Link></li>
                    <li><Link className="dropdown-item" to="/bayaans/mahmood/Khutbaat-e-Jummah">Khutbat-E-Jummah</Link></li>
                  </ul>
                </li>
                <li><Link className="dropdown-item" to="/bayaans/masood">Molana Syed Masood Mian Sahab</Link></li>
              </ul>
            </li>
            <li className="nav-item dropdown">
              <a className="nav-link" href="">Maqalat</a>
              <ul className="dropdown-menu">
                <li><a className="dropdown-item" href="/Maqalat-e-Mian">Maqaalat-e-Muhammad Mian</a></li>
                <li><a className="dropdown-item" href="/Maqalat-e-Hamdiyah">Maqalat-e-Hamidiyah</a></li>
              </ul>
            </li>
            <li className="nav-item">
              <a className="nav-link" href="/Darulifta">Darulifta</a>
            </li>
            <li className="nav-item">
              <a className="nav-link" href="/">Photogallery</a>
            </li>
          </ul>
          <a className="navbar-brand ms-auto" href="/">{props.title}</a>
        </div>
      </div>
    </nav>
  );
}
Navbar.propTypes = {
  title: PropTypes.string.isRequired,
  aboutText: PropTypes.string.isRequired,
  mode: PropTypes.string.isRequired,
};
