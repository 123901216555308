import React from 'react';
import newbookbg from '../images/newbookbg.jpg';
import zadsaeed from '../images/zadsaeed.jpg';
import './NewBook.css';

export default function NewBook() {
    return (
        <div className='NewBookContainer'>
            <img src={newbookbg} alt="Background" className="NewBookBackground" />
            <div className="NewBookBox">
                <div className="NewBookHeader">
                    <h1>نئی کتاب</h1>
                </div>
                <div className="NewBookContent">
                    <div className="NewBookIcon">
                        <img src={zadsaeed} className="zadsaeed" alt="Zad Saeed" />
                    </div>
                    <div className="NewBookBox-text">
                        <h2>زاد السعید</h2>
                        <span>
                            سید المرسلین وخاتم النبیین آنحضرت ﷺ کی خدمت اقدس میں <br />
                            درود شریف کی سوغات کا ہدیہ ارسال کرنا اللہ رب العزت کے نزدیک محبوب ترین عمل،<br />
                            لذیذ ترین عبادت اور رسول اللہ ﷺ کی محبت میں اضافے کاذریعہ ہے۔<br />
                            اس مبارک عمل پر دوام اللہ عز وجل کی رضا و قرب کا سبب <br />
                            ،اس کی برکت سے گناہوں کی بخشش، دنیاوی حیات میں برکات کا نزول،<br />
                            آخرت میں سرخروئی شفاعت نبوی ﷺ اور قرب نبوی ﷺ کا موجب ہے۔
                        </span>
                    </div>
                </div>
            </div>
        </div>
    );
}
